<template>
  <div class="container mt-5 py-5">
    <div class="row">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4 font-weight-bold text-secondary">Solicitar</h4>
          <p class="para-desc text-muted mb-0">
            A traves de nuestros
            <span class="text-primary font-weight-bold">Accesos directos</span>
            puedes realizar tus solicitudes sin ninguna complicacion y de manera
            <span class="text-primary font-weight-light">Rapida y Segura</span>
          </p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div
        class="col-lg-3 col-md-6 pt-2"
        v-for="(card, idx) in props.cards"
        v-bind:key="idx"
      >
        <div
          class="
            card
            border-0
            features
            feature-clean
            course-feature
            p-4
            overflow-hidden
            shadow
          "
        >
          <div class="icons text-primary text-center">
            <i v-bind:class="card.icon"></i>
          </div>
          <div class="card-body p-0 mt-4">
            <a
              href="javascript:void(0)"
              class="title h5 text-dark font-weight-bold text-primary"
              >{{ card.title }}</a
            >
            <p class="text-muted mt-2">
              {{ card.description }}
            </p>
            <a
              v-if="card.btnTxt"
              href="javascript:void(0)"
              class="text-primary read-more"
              >{{ card.btnTxt }} <i class="mdi mdi-chevron-right"></i></a
            ><i class="uil uil-layer-group text-primary full-img"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>
